import React from 'react'
import { Layout, PortfolioSection } from 'organisms'

const PortfolioPage = () => (
	<Layout>
		<PortfolioSection />
	</Layout>
)

export default PortfolioPage
